<template>
    <div id="goods_purchase">
        <div class="goods_params wrap">
            <div class="block clearfix">
                <div class="left_info">仓储：</div>
                <ul class="fl">
                    <li @click="storageType = 1" :class="storageType==1 ? 'active':''">全部仓储</li>
                    <li @click="storageType = 2" :class="storageType==2 ? 'active':''">义乌仓储</li>
                    <li @click="storageType = 3" :class="storageType==3 ? 'active':''">广州（中通）仓</li>
                </ul>
            </div>
            <div class="block clearfix">
                <div class="left_info">排序：</div>
                <ul class="fl">
                    <li @click="sortType = 1" :class="sortType==1 ? 'active':''">默认排序</li>
                    <li @click="sortType = 2" :class="sortType==2 ? 'active':''">价格最低</li>
                    <li @click="sortType = 4" :class="sortType==4 ? 'active':''">重量最重</li>
                    <li @click="sortType = 5" :class="sortType==5 ? 'active':''">最新加入</li>
                </ul>
            </div>
        </div>
        <div class="goods_list wrap clearfix">
            <div class="item">
                <dl>
                    <dt>
                        <img src="@/assets/img/06.jpg" alt="">
                    </dt>
                    <dd class="title">
                        洗衣机槽泡腾片一盒  12颗装可用半年
                    </dd>
                    <dd class="other">
                        <span class="price fl">￥2.30</span>
                        <span class="fr">0.15 kg/件</span>
                    </dd>
                    <dd class="num">
                        库存：8324件
                    </dd>
                    <dd class="btn">
                        立即购买
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            sortType: 1,
            storageType: 1,
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#goods_purchase{
    .goods_params{
        border: 1px solid #eee;
        border-radius: 4px;
        font-size: 14px;
        color: #666;
        .block{
            line-height: 50px;
            background: #fafafa;
            &:nth-child(1){
                border-bottom: 1px solid #eee;
                background: #fff;
            }
            .left_info{
                float: left;
                padding-left: 20px;
                padding-right: 15px;
            }
            line-height: 50px;
            ul{
                li{
                    float: left;
                    padding: 0 10px;
                    &:hover{
                        cursor: pointer;
                        color: #ff464e;
                    }
                }
                .active{
                    font-weight: bold;
                    color: #ff464e;
                }
            }
        }
    }
    .goods_list{
        margin-top: 20px;
        margin-bottom: 20px;
        .item{
            width: 226px;
            border: 1px solid #eee;
            margin-bottom: 10px;
            margin-left: 10px;
            float: left;
            &:hover{
                cursor: pointer;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
            }
            dl{
                dt{
                    width: 210px;
                    margin: 0 auto;
                    margin-top: 8px;
                    img{
                        width: 210px;
                        height: 210px;
                    }
                }
                dd{
                    padding: 0 8px;
                    font-size: 14px;
                    color: #999;
                }
                .title{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 34px;
                }
                .other{
                    height: 28px;
                    .price{
                        color: #ff464e;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
                .num{
                    line-height: 34px;
                    color: #333;
                    border-top: 1px solid #efefef;
                    border-bottom: 1px solid #efefef;
                }
                .btn{
                    border-radius: 4px;
                    color: #ff464e;
                    border: 1px solid #ff464e;
                    line-height: 28px;
                    width: 180px;
                    text-align: center;
                    margin: 10px auto;
                    &:hover{
                        background: #ff464e;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>